<template>
    <div>
        <v-icon class="arrow-color"
                size="20"
                @click="back">
            mdi-arrow-left
        </v-icon>
        <span class="text-black font-weight-bold pl-5 size">{{ $t('View Booking') }}
            <v-btn depressed
                   class="owner-info-btn rounded-lg white--text text-capitalize float-right font-weight-thin"
                   @click="ownerInfo">
                {{ $t('Owner Info') }}
            </v-btn>
        </span>

        <v-divider color="white"
                   class="mt-7 mb-7"></v-divider>
        <v-card>
            <v-card-title class="font-weight-bold size pt-7">
                <div class="text-green">
                    {{ $t('User Details') }}
                </div>
            </v-card-title>
            <v-row class="pl-6">
                <v-col v-for="item in users"
                       :key="item.id"
                       cols="12"
                       lg="2"
                       md="2"
                       sm="3">
                    <div class="text-green font-weight-regular text-caption">
                        {{ $t(item.id) }}
                    </div>
                    <p class="mt-1 text-black text-caption font-weight-bold">
                        {{ item.text }}
                    </p>
                </v-col>
            </v-row>
        </v-card>

        <OwnerDialog :owner-infos="ownerInfos"
                     :modal="modall"
                     @close="cancellDailog"
                     @closed="closePopup"></OwnerDialog>

        <CancellationDialog :modal="cancelModal"
                            @reject="rejectDialog"
                            @dismiss="dismissDialog"></CancellationDialog>

        <v-card class="mt-7">
            <v-card-title class="font-weight-bold size pt-6">
                <div class="text-green">
                    {{ $t('Booking Details') }}
                </div>
            </v-card-title>
            <v-row class="mt-n10">
                <v-col cols="12"
                       md="6"
                       sm="6">
                    <v-card-title class="text-body-2 font-weight-bold">
                        <span class="text-black">{{ boatDetail.boat_name }}</span>
                        <v-chip x-small
                                :color="resolveStatusVariant(status).variant"
                                :class="`mx-3 v-chip-light-bg ${resolveStatusVariant(status).variant}--text font-weight-thin text-caption`">
                            {{ $t(resolveStatusVariant(status).name) }}
                        </v-chip>

                    </v-card-title>
                    <v-card-subtitle class="mt-n4">
                        <v-icon size="15">
                            mdi-map-marker-radius-outline
                        </v-icon>
                        <span class="ml-2 text-caption text-black">{{ city ? city.name + ' | ' : '' }} {{ country ?
                            country.name :
                            ''
                        }}</span>
                        <div class="mt-1 mb-4">
                            <v-icon size="15">
                                mdi-calendar-month-outline
                            </v-icon>
                            <span class="ml-2 text-caption text-black">{{ created_at }}</span>
                        </div>
                        <v-divider></v-divider>
                    </v-card-subtitle>
                    <v-card-title class="text-body-1 mt-n4">
                        <span class="text-black">{{ $t('Equipment(s)') }}</span>
                    </v-card-title>

                    <v-card-subtitle>
                        <v-row dense>
                            <v-col v-for="item in boatConditions"
                                   v-if="item.value == true"
                                   :key="item.id"
                                   cols="12"
                                   md="4"
                                   sm="4">
                                <!-- <img v-if="item.value == true" width="15px" :src="require('@/assets/images/polish.png')"> -->
                                <img :src="item.icon"
                                     width="14px">
                                <span class="ml-2 font-weight-thin text-caption text-black">{{ $t(item.id) }}</span>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4"></v-divider>
                    </v-card-subtitle>

                    <v-card-subtitle>
                        <div class="text-green font-weight-medium text-caption mb-1">
                            {{ $t('Booking ID') }}
                            <span class="float-right text-black text-caption font-weight-bold">{{ bookingId }}</span>
                        </div>
                        <div class="text-green font-weight-medium text-caption mb-1">
                            {{ $t('Boat ID') }}
                            <span class="float-right text-black text-caption font-weight-bold">{{ boatId }}</span>
                        </div>
                        <div class="text-green font-weight-medium text-caption mb-1">
                            {{ $t('Payment Method') }}
                            <span class="float-right text-black text-caption font-weight-bold">{{ payment_method }}</span>
                        </div>
                        <div v-if="boatPackages.length > 0">
                            <div v-for="(item, i) in boatPackages"
                                 :key="item"
                                 class="text-green font-weight-medium text-caption mb-1">
                                {{ $t(item.name) }}
                                <span class="float-right text-black text-caption font-weight-bold">{{
                                    item.price }}
                                    SAR</span>
                            </div>
                        </div>

                        <div class="text-green font-weight-medium text-caption mb-1">
                            {{ $t('Price of Booking') }}
                            <span class="float-right text-black text-caption font-weight-bold">
                                {{ booking_price }} SAR ({{ (totalHours) }} Hours)</span>
                        </div>
                        <v-divider class="mt-4"></v-divider>
                    </v-card-subtitle>

                    <v-card-subtitle>
                        <div class="text-green font-weight-medium text-caption mb-1">
                            {{ $t('Order Amount') }}
                            <span class="float-right text-black text-caption font-weight-bold">{{ order_amount }} SAR</span>
                        </div>
                        <div class="text-green font-weight-medium text-caption">
                            {{ $t('MyBoat Commission') }}
                            <span class="float-right text-black text-caption font-weight-bold">
                                {{ myBoatCommision }} SAR ({{ (boat_commission) }}%)</span>
                        </div>
                        <div class="text-green font-weight-medium text-caption">
                            {{ $t('VAT') }}
                            <span class="float-right text-black text-caption font-weight-bold">{{ booking_vat }}%</span>
                        </div>
                        <v-divider class="mt-3"></v-divider>
                    </v-card-subtitle>

                    <v-card-subtitle>
                        <div class="text-green font-weight-bold text-caption mt-n2 mb-1">
                            {{ $t('Total Amount') }}
                            <span class="float-right text-black text-caption font-weight-bold">{{ total_amount }} SAR</span>
                        </div>
                        <v-divider></v-divider>
                    </v-card-subtitle>
                </v-col>

                <v-col cols="12"
                       md="5"
                       sm="5">
                    <v-card-subtitle class="text-black">
                        {{ $t('Photos') }}
                    </v-card-subtitle>
                    <v-container>
                        <template>
                            <v-carousel hide-delimiters
                                        height="250">
                                <v-carousel-item v-for="(item, i) in pictures"
                                                 :key="i"
                                                 :class="{ active: i === currentImageIndex }"
                                                 :src="item.src"></v-carousel-item>
                            </v-carousel>
                        </template>
                        <div class="image-container">
                            <div v-for="(item, i) in pictures"
                                 :key="i"
                                 class="image-wrapper"
                                 @click="showCurrentImg(item, i)">
                                <img :src="item.src"
                                     alt="BoatImages"
                                     width="70"
                                     height="45"
                                     class="ml-2 mt-5 ">
                            </div>
                        </div>
                        <div v-if="status != 'confirmed' && status != 'in_progress' && status != 'cancelled' && status != 'completed' && role.role != 'boat_owner'"
                             class="d-flex align-end top">
                            <v-btn class="accept-booking rounded-lg text-capitalize white--text font-weight-thin px-md-10"
                                   @click="booking('Accept')">
                                {{ $t('Accept Booking') }}
                            </v-btn>
                            <v-btn class="reject-booking rounded-lg text-capitalize white--text font-weight-thin ml-5 px-md-13"
                                   @click="booking('Reject')">
                                {{ $t('Reject Booking') }}
                            </v-btn>
                        </div>

                        <div v-if="status == 'in_progress'"
                             class="d-flex align-end top justify-end">
                            <v-btn class="accept-booking rounded-lg text-capitalize white--text font-weight-thin px-md-10"
                                   @click="completeBooking()">
                                {{ $t('Check out') }}
                            </v-btn>
                        </div>

                        <div v-if="status == 'confirmed'"
                             class="d-flex align-end top justify-end">
                            <v-btn class="accept-booking rounded-lg text-capitalize white--text font-weight-thin px-md-10"
                                   @click="inProgressBooking()">
                                {{ $t('Check In') }}
                            </v-btn>
                        </div>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import router from '@/router'
import bookingServices from '@/services/booking-services'
import CancellationDialog from '@/views/dialogs/CancellationDialog.vue'
import OwnerDialog from '@/views/dialogs/OwnerDialog.vue'
import moment from 'moment'
import config from '/config'
import darkMode from '@core/utils/modalColor';

export default {
    components: {
        OwnerDialog,
        CancellationDialog,
    },
    data: () => ({
        bookingId: '',
        boatDetail: '',
        status: '',
        currentImageIndex: 0,
        boatOwnerInfo: null,
        ownerInfos: {},
        boatPackages: [],
        city: '',
        country: '',
        from_time: '',
        to_time: '',
        created_at: '',
        boatId: '',
        fishing_package: '',
        cancelModal: false,
        modall: false,
        order_amount: '',
        payment_method: '',
        totalHours: '',
        total_amount: '',
        booking_vat: '',
        role: 'admin',
        boat_commission: '',
        myBoatCommision: '',
        users: [],
        boatConditions: [
        ],
        pictures: [
        ],
        location: '',
    }),

    mounted()
    {
        // this.getApplicationList();
        this.role = JSON.parse(localStorage.getItem('userData'))
        this.bookingId = router.currentRoute.params.id
        if (this.bookingId)
        {
            this.getBookings()
        }
    },
    methods: {
        resolveStatusVariant(status)
        {
            if (status === 'hold') return { variant: 'secondary', name: 'Pending' }
            if (status === 'pending') return { variant: 'success', name: 'Pending' }
            if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
            if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
            if (status === 'in_progress') return { variant: 'warning', name: 'In Progress' }
            if (status === 'completed') return { variant: 'success', name: 'Completed' }
            // if (status === 'pending_boat_inspection') return { variant: 'info', name: 'Pending Inspection' }

            return { variant: 'secondary', name: 'Not Available' }
        },
        showCurrentImg(item, index)
        {
            this.currentImageIndex = index
            this.pictures[index].src = item.src
            // console.log(this.pictures)
        },
        closePopup()
        {
            this.modall = false
        },
        completeBooking()
        {
            let dict
            let url
            url = 'boat_bookings/completed'
            dict = {
                id: this.bookingId,
                status: 'completed',
                booking_notes: 'no completed',
            }
            bookingServices.updateApplicationStatus(dict, url).then(resp =>
            {
                // console.log(resp)
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                } else
                {
                    this.updateStatus = 'statusChange'
                    this.$swal({
                        title: 'Checked Out',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        router.push('/booking/list')

                        // refresh here
                    })
                }
            })
        },

        inProgressBooking()
        {
            let dict
            let url
            url = 'boat_bookings/in-progress'
            dict = {
                id: this.bookingId,
                status: 'in_progress',
                booking_notes: 'no booking_notes',
            }
            bookingServices.updateApplicationStatus(dict, url).then(resp =>
            {
                // console.log(resp)
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                } else
                {
                    this.updateStatus = 'statusChange'
                    this.$swal({
                        title: 'Checked In',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        // router.push('/booking/list');
                        // refresh here
                        this.pictures = [];
                        this.getBookings()
                    })
                }
            })
        },

        booking(status)
        {
            if (status == 'Accept')
            {
                // this.$swal({
                //     title: 'Update Booking',
                //     // text: headTxt,
                //     text: "Do you want to " + status + " this booking",
                //     type: "warning",
                //     input: 'textarea',
                //     inputAttributes: {
                //         autocapitalize: 'off'
                //     },
                //     showCancelButton: true,
                //     confirmButtonText: 'Yes',
                //     confirmButtonColor: '#00b2a9',
                //     showLoaderOnConfirm: true,
                //     preConfirm: (login) =>
                //     {
                //         return login;
                //     }
                // }).then((result) =>
                // {

                // if (result.isConfirmed)
                // {
                    let dict
                    let url
                    url = 'boat_bookings/accept'
                    // console.log(result)
                    dict = {
                        id: this.bookingId,
                        status: 'confirmed',
                    }
                    bookingServices.updateApplicationStatus(dict, url).then(resp =>
                    {
                        this.options = []
                        // console.log(resp)
                        if (resp.error)
                        {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                text: 'Something went wrong!',
                            })
                        } else
                        {
                            this.updateStatus = 'statusChange'
                            this.$swal({
                                title: 'Status Updated',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                icon: 'success',
                            }).then(result =>
                            {
                                this.pictures = [];
                                this.getBookings()
                            })
                        }
                    })
                // }

                // });
            } else
            {
                this.cancelModal = true
            }
        },

        cancellDailog()
        {
            this.modall = false
        },

        dismissDialog(event)
        {
            // console.log(event)
            this.cancelModal = false
        },

        rejectDialog(event)
        {
            // console.log(event)
            this.cancelModal = false
            let dict
            let url
            url = 'boat_bookings/reject'
            dict = {
                id: this.bookingId,
                status: 'cancelled',
                rejection_notes: event || null,
            }
            bookingServices.updateApplicationStatus(dict, url).then(resp =>
            {
                // console.log(resp)
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                } else
                {
                    this.updateStatus = 'statusChange'
                    this.$swal({
                        title: 'Status Rejected',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        router.push('/booking/list')

                        // refresh here
                    })
                }
            })
        },

        getBookings()
        {
            bookingServices.viewBooking(this.bookingId).then(resp =>
            {
                // const { filteredData, total } = response.data

                if (resp.status === 200)
                {
                    if (resp.data.data.bookings[0].boat.boats_owner)
                    {
                        this.boatOwnerInfo = resp.data.data.bookings[0].boat.boats_owner
                    }
                    if (resp.data.data.bookings[0].booking_packages)
                    {
                        this.boatPackages = resp.data.data.bookings[0].booking_packages;
                    }
                    if (resp.data.data.bookings[0]?.boat.id)
                    {
                        this.boatId = resp.data.data.bookings[0].boat.id;
                    }
                    if (resp.data.data.bookings[0].user)
                    {
                        this.booking_price = resp.data.data.bookings[0].booking_price
                        this.fishing_package = resp.data.data.bookings[0].fishing_package
                        this.payment_method = resp.data.data.bookings[0].payment_method
                        this.booking_vat = resp.data.data.bookings[0].booking_vat
                        this.total_amount = resp.data.data.bookings[0].total_amount
                        this.order_amount = resp.data.data.bookings[0].order_amount
                        this.boat_commission = resp.data.data.bookings[0].boat_commission
                        if (this.boat_commission != null && this.boat_commission > 0)
                        {
                            this.myBoatCommision = (this.total_amount / 100) * this.boat_commission
                            this.myBoatCommision = this.myBoatCommision.toFixed(2)
                        }

                        this.created_at = moment(new Date(resp.data.data.bookings[0].on_date)).format('DD/MM/yyyy')

                        // this.from_time = resp.data.data.bookings[0].from_time
                        // this.to_time = resp.data.data.bookings[0].to_time

                        if (resp.data.data.bookings[0].from_time)
                        {
                            this.created_at = `${this.created_at} | ${resp.data.data.bookings[0].from_time}`
                        }
                        if (resp.data.data.bookings[0].to_time)
                        {
                            this.created_at = `${this.created_at} - ${resp.data.data.bookings[0].to_time}`
                        }

                        /// get hours//////
                        if (resp.data.data.bookings[0].from_date_time && resp.data.data.bookings[0].to_date_time)
                        {
                            const fromDate = new Date(resp.data.data.bookings[0].from_date_time)
                            const toDate = new Date(resp.data.data.bookings[0].to_date_time)

                            const timeDiff = toDate.getTime() - fromDate.getTime()
                            const hours = Math.floor(timeDiff / (1000 * 60 * 60))

                            this.totalHours = hours
                        }

                        this.users = [
                            { id: 'User ID', text: resp.data.data.bookings[0].user.id },
                            { id: 'Username', text: `${resp.data.data.bookings[0].user.first_name} ${resp.data.data.bookings[0].user.last_name}` },
                            { id: 'Mobile No.', text: resp.data.data.bookings[0].user.phone },
                            { id: 'Email Address', text: resp.data.data.bookings[0].user.email },

                            // { id: 'Requested Date', text: resp.data.data.bookings[0].on_date },
                            { id: 'Requested Date', text: moment(new Date(resp.data.data.bookings[0].created_at)).format('DD/MM/yyyy') },
                        ]
                        if (resp.data.data.bookings[0].boat.boats_condition)
                        {
                            this.boatConditions = [
                                { id: 'Polish', value: resp.data.data.bookings[0].boat?.boats_condition.polish, icon: require('@/assets/images/polish.png') },
                                { id: 'Anti Fouling', value: resp.data.data.bookings[0].boat?.boats_condition.anti_fouling, icon: require('@/assets/images/anti.png') },
                                { id: 'Washroom', value: resp.data.data.bookings[0].boat?.boats_condition.wash_room, icon: require('@/assets/images/washroom.png') },
                                { id: 'Cleanliness', value: resp.data.data.bookings[0].boat?.boats_condition.cleanliness, icon: require('@/assets/images/clean.png') },
                                { id: 'Life Jackets', value: resp.data.data.bookings[0].boat?.boats_condition.life_jacket, icon: require('@/assets/images/jackets.png') },
                                { id: 'Fire Extinguisher', value: resp.data.data.bookings[0].boat?.boats_condition.fire_extinguisher, icon: require('@/assets/images/fire.png') },
                                { id: 'Paint', value: resp.data.data.bookings[0].boat?.boats_condition.paint, icon: require('@/assets/images/paint.png') },
                                { id: 'Air Conditioner', value: resp.data.data.bookings[0].boat?.boats_condition.air_conditioner, icon: require('@/assets/images/ac.png') },
                                { id: 'Rooms', value: resp.data.data.bookings[0].boat?.boats_condition.rooms, icon: require('@/assets/images/rooms.png') },
                                { id: 'Salon', value: resp.data.data.bookings[0].boat?.boats_condition.salon, icon: require('@/assets/images/salon.png') },
                                { id: 'Smoke', value: resp.data.data.bookings[0].boat?.boats_condition.smoke, icon: require('@/assets/images/smoke.png') },
                                { id: 'Gearbox Sound', value: resp.data.data.bookings[0].boat?.boats_condition.gear_sound_box, icon: require('@/assets/images/sound.png') },
                                { id: 'Depth Sounder', value: resp.data.data.bookings[0].boat?.boats_condition.depth_sounder, icon: require('@/assets/images/sounder.png') },
                                { id: 'Bimini', value: resp.data.data.bookings[0].boat?.boats_condition.bimini, icon: require('@/assets/images/bimini.png') },
                                { id: 'GPS', value: resp.data.data.bookings[0].boat?.boats_condition.gps, icon: require('@/assets/images/gps.png') },
                                { id: 'USB Socket', value: resp.data.data.bookings[0].boat?.boats_condition.usb_socket, icon: require('@/assets/images/usb.png') },
                            ]
                        }

                        if (resp.data.data.bookings[0].boat.boat_pictures)
                        {
                            resp.data.data.bookings[0].boat.boat_pictures.forEach(element =>
                            {
                                this.pictures.push({
                                    src: config.MYBOAT_IMG_URL + element.image,
                                })
                            })
                        }
                    }

                    if (resp.data.data.bookings[0].boat)
                    {
                        this.boatDetail = resp.data.data.bookings[0].boat
                        // console.log(this.boatDetail.boat_name)
                    }

                    if (resp.data.data.bookings[0].boat.country)
                    {
                        this.country = resp.data.data.bookings[0].boat.country
                        // console.log(this.boatDetail.boat_name)
                    }

                    this.status = resp.data.data.bookings[0].status
                    if (resp.data.data.bookings[0].city)
                    {
                        this.city = resp.data.data.bookings[0].city
                    }
                }
            })
        },
        back()
        {
            router.push({ name: 'booking-list' })
        },
        ownerInfo()
        {
            this.modall = true
            this.cancelModal = false
            if (this.boatOwnerInfo)
            {
                this.ownerInfos = this.boatOwnerInfo

                // console.log(this.ownerInfos)
            }
        },
    },
}
</script>

<style scoped>
.size {
    font-size: 20px;
}

.owner-info-btn {
    background-color: #00B2A9 !important;
}

.color {
    color: #00B2A9;
}

.image-container {

    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

}

.image-wrapper {
    display: inline-block;
}

.btn-text {
    color: #139536;
}

.top {
    margin-top: 200px;
}

::v-deep .v-chip.v-size--x-small {
    border-radius: 3px !important;
    height: 24px !important;
}
</style>
