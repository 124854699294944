<template>
    <div>
        <template>
            <v-row justify="center"
                   no-gutters>
                <v-dialog v-model="modal"
                          persistent
                          max-width="1000">
                    <v-card>
                        <div class="float-right mr-5 mt-3 px-2 py-1 rounded-circle"
                             @click="closeDialog"
                             style="background-color:#FAFAFA;cursor: pointer;">
                            <v-icon color="#00B2A9"
                                    size="15"
                                    class="mt-n1 mx-auto">mdi-close-circle-outline</v-icon>
                        </div>
                        <v-card-title>
                            <div class="d-flex align-center">

                                <span class="font-weight-semibold text-base text-green">{{ $t('Owner Information') }}</span>

                            </div>
                        </v-card-title>

                        <v-card-text>

                            <v-row class="mt-n4">
                                <v-col cols="12"
                                       md="6"
                                       lg="4"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Owner Name') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Owner name')"
                                                  v-model="owner_name"
                                                  :disabled="true"
                                                  variant="underlined"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Owner Type') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Owner Type')"
                                                  variant="underlined"
                                                  v-model="owner_type"
                                                  :disabled="true"
                                                  hide-details
                                                  class="text-fields"></v-text-field>
                                </v-col>

                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('ID No.') }}
                                    </p>
                                    <v-text-field :placeholder="$t('ID No')"
                                                  variant="underlined"
                                                  v-model="id_no"
                                                  :disabled="true"
                                                  hide-details
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Certificate of Owner Registration') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Certificate of Owner Registration')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  hide-details
                                                  v-model="certificate"
                                                  class="text-fields"></v-text-field>

                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Country') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Country')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  hide-details
                                                  v-model="country"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Unit') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Unit')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  hide-details
                                                  v-model="unit"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Street') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Street')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  hide-details
                                                  v-model="street"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('District') }}
                                    </p>
                                    <v-text-field :placeholder="$t('District')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="district"
                                                  hide-details
                                                  class="text-fields"></v-text-field>

                                </v-col>
                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Structure No.') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Structure No.')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="structure_no"
                                                  hide-details
                                                  class="text-fields"></v-text-field>
                                </v-col>



                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('City') }}
                                    </p>
                                    <v-text-field :placeholder="$t('City')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="city"
                                                  hide-details
                                                  class="text-fields"></v-text-field>

                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Phone') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Phone')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="phone"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Email ID') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Email')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="email"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12"
                                       md="12"
                                       sm="12">
                                    <div class="d-flex align-center">
                                        <span class="text-fields font-weight-semibold text-base text-green">
                                            {{ $t('Account Information') }}</span>
                                    </div>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Bank Name') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Bank name')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="bank_name"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Branch') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Branch')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="branch"
                                                  class="text-fields"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('IBAN') }}
                                    </p>
                                    <v-text-field :placeholder="$t('IBAN')"
                                                  variant="underlined"
                                                  :disabled="true"
                                                  v-model="iban"
                                                  class="text-fields"></v-text-field>
                                </v-col>

                            </v-row>

                        </v-card-text>

                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>

<script>
import sharedServices from '@/services/shared-services';
export default {
    props: {
        modal: {
            default: false,
            type: Boolean
        },
        ownerInfos: {
            default()
            {
                return {}
            },
            type: Object
        },

    },
    data: () => ({
        owner_name: '',
        owner_type: '',
        id_no: '',
        certificate: '',
        country: '',
        unit: '',
        street: '',
        district: '',
        structure_no: '',
        city: '',
        phone: '',
        email: '',
        bank_name: '',
        branch: '',
        iban: '',
        dialog: true,
        cities: [],
        countries: [],
        localObject: {}
    }),

    mounted()
    {
        this.getCities();
        this.getCountries();
    },

    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        ownerInfos: {
            immediate: true,
            handler(newVal)
            {
            
                this.localObject = Object.assign({}, newVal);
                this.cities.forEach(element => {
                     if(element.id == this.localObject.city){
                        this.city = element.name;
                     }
                });
                this.countries.forEach(element => {
                     if(element.id == this.localObject.country_id){
                        this.country = element.name;
                     }
                });
                this.owner_name = this.localObject.owner_name
                this.owner_type = this.localObject.owner_type
                this.id_no = this.localObject.information_id
                this.certificate = this.localObject.certificate
                this.unit = this.localObject.unit
                this.street = this.localObject.address
                this.district = this.localObject.district
                this.structure_no = this.localObject.structure_number
                this.phone = this.localObject.phone
                this.email = this.localObject.email
                this.bank_name = this.localObject.bank_name
                this.branch = this.localObject.bank_branch
                this.iban = this.localObject.bank_iban
            }
        }
    },
    // mounted()
    // {
    //     
    //     this.owner_name = this.ownerInfos?.owner_name;
    //     // // console.log(this.ownerInfos)
    // },

    methods: {
        closeDialog()
        {
            this.$emit('closed');
            return this.modal = !this.modal;
        },
        getCities()
        {
            sharedServices.getCities().then(resp =>
            {
                // console.log(resp)
                this.cities = resp.data.cities;
            })
        },
        getCountries()
        {
            sharedServices.getCountries().then(resp =>
            {
                // console.log(resp)
                this.countries = resp.data.countries;
            })
        },
    }
}
</script>

<style scoped>
.text-fields {
    margin: -4px 0px 0px;
}

.text-black {
    color: #000;
}

.circle {
    border: 2px solid red;
    border-radius: 50px 50px 50px 50px;
    /* Make it circular */
}
</style>